<template>
	<!-- 限时秒杀活动编辑 -->
	<div class="adhibition-seckill-edit">
		<div class="el-content" v-if="sence!='add'" style="text-align: right;">
			<a-button type="primary" v-if="sence == 'session'" @click="sence = 'edit'">编辑秒杀活动</a-button>
			<a-button type="primary" 
				v-if="sence == 'edit'" 
				@click="changeEditSession" 
				v-has="{action:'wxapp_adhibition_seckill_set_session',plat:0}">编辑秒杀场次
			</a-button>
			<a-button @click="$router.go(-1)">返回</a-button>
		</div>
		<div class="el-content">
			<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 12 }">
				<a-form-item label="秒杀活动名称">
					<a-input v-model:value="form.title" placeholder="秒杀活动名称"></a-input>
				</a-form-item>
				<a-form-item label="秒杀开始时间">
					<a-date-picker v-model:value="form.start_time"></a-date-picker>
				</a-form-item>
				<a-form-item label="秒杀结束时间">
					<a-date-picker v-model:value="form.end_time"></a-date-picker>
				</a-form-item>
				<a-form-item label="秒杀活动状态">
					<a-radio-group v-model:value="form.is_put_away">
					    <a-radio :value="1">启用</a-radio>
					    <a-radio :value="0">禁用</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="秒杀场次" v-if="sence == 'session'">
					<a-button @click="editSession(false)"  type="primary">添加场次</a-button>
					<a-table :pagination="false" row-key="id" :data-source="sessionList" :columns="[
						{title:'ID',dataIndex:'id'},
						{title:'秒杀场次名称',dataIndex:'title'},
						{title:'秒杀开始时间',dataIndex:'start_time'},
						{title:'秒杀结束时间',dataIndex:'end_time'},
						{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
					]" >
						<template #action="{record}">
							<a-space>
								<a-button type="link" size="small" @click="setGoods(record.id)">设置商品</a-button>
								<a-button type="link" size="small" @click="editSession(record)">编辑</a-button>
								<a-button type="link" size="small" @click="delSession(record.id)">删除</a-button>
							</a-space>
						</template>
					</a-table>
				</a-form-item>
				<a-form-item v-if="sence !='session'" :wrapper-col="{ offset: 2 }">
					<a-space>
						<a-button type="primary" @click="submitSave">保存</a-button>
						<a-button @click="$router.go(-1)">返回</a-button>
					</a-space>
				</a-form-item>
			</a-form>
		</div>
		
		<a-modal v-model:visible="show.sessionModel" title="添加活动场次" @ok="submitSessionForm" width="600px" @cancel="show.sessionModel = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="秒杀开始时间">
					<a-time-picker v-model:value="sessionForm.start_time" format="HH:mm" @change="timeChange"/>
				</a-form-item>
				<a-form-item label="秒杀结束时间">
					<a-time-picker v-model:value="sessionForm.end_time" format="HH:mm" @change="timeChange"/>
				</a-form-item>
				<a-form-item label="秒杀活动名称">
					<a-input v-model:value="sessionForm.title" placeholder="秒杀活动名称"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>

		<a-modal v-model:visible="show.setModel" title="添加活动场次" :footer="null" width="1000px" @cancel="show.setModel = false">
			<a-space>
				<div style="display:inline-block;">
					<com-select-table-list :sku="0" :mutil="true" @getData="getMultipleData">
						<a-button type="primary">添加商品</a-button>
					</com-select-table-list>
				</div>
				<a-button type="primary" @click="saveGoods">保存</a-button>
			</a-space>
			<span style="color: #999;font-size: 12px;">编辑或新增商品后请点击保存</span>
			<span style="color: red;font-size: 12px;"> 提示：多规格商品不能参与秒杀活动</span>
			<a-table :pagination="false" row-key="id" :data-source="goods.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'商品名称',dataIndex:'goods.goods_name'},
				{title:'秒杀价格',dataIndex:'price',slots:{customRender:'price'}},
				{title:'参与场次秒杀数量',dataIndex:'stock',slots:{customRender:'stock'}},
				{title:'每人限购',dataIndex:'limit',slots:{customRender:'limit'}},
				{title:'显示顺序',dataIndex:'rank',slots:{customRender:'rank'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #price="{record}">
					<a-input v-model:value="record.price" />
				</template>
				<template #stock="{record}">
					<a-input v-model:value="record.stock" />
				</template>
				<template #limit="{record}">
					<a-input v-model:value="record.limit" />
				</template>
				<template #rank="{record}">
					<a-input v-model:value="record.rank" />
				</template>
				<template #action="{record,index}">
					<a-button type="link" @click="delSessionGoods(record,index)">删除</a-button>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="goods.page"
					:total="goods.count"
					@showSizeChange="(p,e)=>{getSessionSetGoods(goods.page,e)}"
					@change="(e)=>{getSessionSetGoods(e,goods.limit)}"
				/>
			</div>
		</a-modal>
		
	</div>
</template>

<script>
import moment from "moment"
import comSelectTableList from "@/components/miniapp/com-select-table-list.vue";
import { reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import seckillModel from '@/api/addons/seckill';
import commonModel from '@/api/common';
export default{
	components:{
		comSelectTableList
	},
	setup(){
		const state = reactive({
			sence:'add',	//操作场景 add=添加活动 edit=编辑活动 session=设置场次
			form:{
				id:0,
				title:"",
				start_time:"",
				end_time:"",
				is_put_away:0,
			},
			sessionList:[],	//秒杀场次
			sessionForm:{
				id:0,
				sid:0,
				title:'',
				start_time:'',
				end_time:'',
			},
			show:{
				sessionModel:false,
				setModel:false,
				goodsModel:false,
			},
			goods:{	//场次商品信息
				list:[],
				page:1,
				limit:10,
				count:0,
				session_id:0,
			},
		})

		const opt = useRoute().query
		state.sence = opt.sence ? opt.sence :"add"
		if( opt.id){
			seckillModel.getSeckillDetail(opt.id,res=>{
				state.form = {
					id:res.id,
					title:res.title || '',
					start_time:moment(res.start_time) ||null,
					end_time:moment(res.end_time) || null,
					is_put_away:res.is_put_away || 0
				}
				state.sessionForm.sid = opt.id
				if( opt.sence == 'session' ) getSeckillSessionList()
			})
		}

		function getSeckillSessionList(){
			seckillModel.getSeckillSession(1,999,{sid:state.form.id},res=>state.sessionList = res)
		}

		function submitSave(){
			let form = JSON.parse(JSON.stringify(state.form))
			seckillModel.addOrEditSeckill(form)
		}

		function submitSessionForm(){
			let sessionForm = JSON.parse(JSON.stringify(state.sessionForm))
			seckillModel.addOrEditSeckillSession(sessionForm,()=>{
				getSeckillSessionList()
				state.show.sessionModel = false
				state.sessionForm = {
					id:0,
					sid:state.form.id,
					title:'',
					start_time:'',
					end_time:'',
				}
			})
		}

		function editSession(row){
			state.show.sessionModel = true
			state.sessionForm = {
				id:row ? row.id :0,
				sid:state.form.id,
				title:row ? row.title :'',
				start_time: row ? moment(row.start_time,"HH:mm"):null,
				end_time:row ? moment(row.end_time,"HH:mm") :null,
			}
		}

		function setGoods(id){
			state.goods.session_id = id
			state.show.setModel = true
			state.goods.list = []
			getSessionSetGoods(1,state.goods.limit)
		}

		function getSessionSetGoods(page,limit){
			let {session_id} = state.goods
			seckillModel.getSessionGoods(page,limit,{session_id},res=>state.goods = {limit,session_id,...res})
		}

		function getGoods(row){
			state.goods.list.push({
				id: 0,
				rank: 99,
				price: '',
				goods_id: row.id,
				session_id: state.goods.session_id,
				limit: 0,
				stock: 0,
				goods:row
			})
			
		}

		function getMultipleData(data){
			data.value.forEach(item=> getGoods(item) )
		}

		function changeEditSession(){
			state.sence = 'session'
			getSeckillSessionList()
		}

		//根据秒杀时间段自动生成秒杀标题
		function timeChange(){
			let { start_time ,end_time } = state.sessionForm
			if( start_time && end_time ){
				state.sessionForm.title = start_time.format("HH:mm")+"-"+end_time.format("HH:mm")
			}
		}

		const delSession = id=>commonModel.deleteDataList(id,52,"确认删除场次信息吗?").then(()=>{
			getSeckillSessionList()
		})

		const saveGoods = ()=>seckillModel.addOrEditSessionGoods(state.goods.list,()=>state.show.setModel = false)
		const delSessionGoods = (record,index)=>{
			if( !record.id ) {
				state.goods.list.splice(index,1)
				return
			}
			commonModel.deleteDataList(record.id,53,"确认删除秒杀商品吗？").then(()=>{
				getSessionSetGoods(state.goods.page,state.goods.limit)
			})
		}
		return{
			...toRefs(state),
			submitSave,
			submitSessionForm,
			getSeckillSessionList,
			editSession,
			setGoods,
			delSession,
			getSessionSetGoods,
			getGoods,
			getMultipleData,
			saveGoods,
			delSessionGoods,
			changeEditSession,
			timeChange
		}
	}
}
</script>

<style lang="scss">
	
</style>
